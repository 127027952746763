import { AuthProvider } from 'react-admin';

import { UserInfo } from './types';
import redirectUri from './discord_consts';
import axios from 'axios';

export const authProvider: AuthProvider = {
  login: async (param = {}) => {
    console.log(`=== login: ${param}`);
    if (!param || !param.state) {
      window.location.href = redirectUri;

      return Promise.reject({
        message: 'Retrieving code from authentication service.',
        code: 'oauthRedirect',
      });
    }

    // const { status: status1, data: data1 } = await axios.get(`https://listing-api.saas.tozorlabs.com/api/auth/me`, { withCredentials: true});
    // if (status1 === 200) {
    //   console.log('Me right away got');
    //   localStorage.setItem('token', JSON.stringify(data1));
    //   return Promise.resolve();
    // }

    const { status: statusUpdate } = await axios.get(
      `https://listing-api.saas.tozorlabs.com/api/auth/update`,
      { withCredentials: true }
    );
    if (statusUpdate !== 204) {
      return Promise.reject({
        message: 'Incorrect update jwt->auth',
        code: 'jwtAuth',
      });
    }
    const { status: statusRemove } = await axios.get(
      `https://listing-api.saas.tozorlabs.com/api/auth/remove_parent`,
      { withCredentials: true }
    );
    if (statusRemove !== 204) {
      return Promise.reject({
        message: 'Incorrect remove jwt',
        code: 'jwtRemove',
      });
    }
    const { status, data } = await axios.get(
      `https://listing-api.saas.tozorlabs.com/api/auth/me`,
      { withCredentials: true }
    );
    if (status !== 200) {
      return Promise.reject({ message: 'Incorrect auth token', code: 'token' });
    }
    localStorage.setItem('token', JSON.stringify(data));
    return Promise.resolve();
  },
  logout: () => {
    console.log('=== logout');
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkError: (error) => {
    console.log('=== checkError');
    const { status } = error;

    if (status && (status === 401 || status === 403)) {
      localStorage.clear();

      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    console.log('=== checkAuth');
    const token = localStorage.getItem('token');

    if (!token) {
      console.log('  reject');
      return Promise.reject();
    }

    console.log('  resolve');
    return Promise.resolve();
  },
  getPermissions: () => {
    console.log('=== getPermissions');
    const token = localStorage.getItem('token');

    return token ? Promise.resolve(token) : Promise.reject();
  },
};

export const authProviderOld: AuthProvider = {
  login: async (userInfo: UserInfo) => {
    if (localStorage.getItem('loginInProcess')) {
      localStorage.removeItem('loginInProcess');
    }
    // console.log('############## LOGIN');
    // console.log('USER INFO: ', userInfo);
    if (userInfo === null) {
      throw new Error('Invalid authentication');
    }
    const time = new Date();
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('lastCheck', time.getTime().toString());
    console.log('Auth finished');
  },
  logout: async () => {
    if (localStorage.getItem('loginInProcess')) {
      // console.log('############ LOGOUT during login. Skipping...');
    } else {
      // console.log('############## LOGOUT');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('lastCheck');
      const response = await fetch('/api/auth/logout', { method: 'delete' });
      console.log('Logout status: ', response.status);
      // return Promise.resolve();
    }
  },
  checkError: (error) => {
    // console.log('############## checkError:', error);
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('lastCheck');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    if (localStorage.getItem('userInfo')) {
      // console.log('############## checkAuth: GOOD');
      return Promise.resolve();
    } else {
      // console.log('############## checkAuth: BAD');
      return Promise.reject();
    }
  },
  getPermissions: () => Promise.resolve(),
  // Promise.reject('Unknown method'),

  // checkError: (error: any) => {
  //     console.log('############## CHECK ERROR')
  //     const status = error.status;
  //     if (status === 401 || status === 403) {
  //         localStorage.removeItem('auth');
  //         return Promise.reject();
  //     }

  //     return Promise.resolve();
  // },

  // checkAuth: () => {
  //     console.log('############## checkAuth')
  //     if (localStorage.getItem('userInfo')) {
  //         console.log('GOOD');
  //         return Promise.resolve();
  //     } else {
  //         console.log('BAD');
  //         return Promise.reject();
  //     }
  // },

  // logout: () => {
  //     console.log('############## logout')
  //     localStorage.removeItem('userInfo');
  //     localStorage.removeItem('lastCheck');
  //     return Promise.resolve();
  // },

  // getPermissions: () => {
  //     console.log('############## getPermissions')
  //     return Promise.resolve('all');
  // },

  getIdentity: () => {
    // console.log('############## getIdentity');
    try {
      const userInfoString = localStorage.getItem('userInfo');
      // console.log('User string: ', userInfoString);
      if (userInfoString) {
        const userInfo: UserInfo = JSON.parse(userInfoString);
        // console.log('User:', userInfo);
        const avatarUrl = userInfo.avatar
          ? `https://cdn.discordapp.com/avatars/${userInfo.userId}/${userInfo.avatar}.png`
          : undefined;
        const output = {
          id: userInfo.userId,
          fullName: userInfo.username,
          avatar: avatarUrl,
        };
        // console.log('Output user: ', output);
        return Promise.resolve(output);
      } else {
        console.log('getIdentity throwing');
        return Promise.resolve({
          id: '0',
          fullName: 'Guest',
        });
      }
    } catch (error) {
      console.log('getIdentity rejecting');
      return Promise.reject(error);
    }
  },
};
