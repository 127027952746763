import {
  DateField,
  NumberField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

const ProductTitle = ({ record }: any) => {
  return <span>{record ? `${record.full_title}` : ''}</span>;
};

export const ProductShow = (props: any) => (
  <Show {...props} title={<ProductTitle />}>
    <SimpleShowLayout>
      <TextField source="full_title" label="Title" />
      <TextField source="native_title" />
      <DateField source="release_date" />
      {/* <TextField source="aired_on" /> */}
      {/* <NumberField source="updated_at" /> */}
      {/* <TextField source="is_completed" /> */}
      <NumberField source="episode_number" />
      <TextField source="country" />
      <UrlField source="mdl_url" target="_blank" label="MyDramaList URL" />
      <RichTextField source="raw_content" label="Content" />
    </SimpleShowLayout>
  </Show>
);
