import React from 'react';
import './App.css';

import { Admin, Resource } from 'react-admin';
import { BrowserRouter } from 'react-router-dom';

import { ProductList } from './ProductList';
import { ProductShow } from './ProductView';
import { authProvider } from './auth/authProvider';
import LoginPage from './auth/LoginPage';
import { dataProvider } from './dataProvider';

function App() {
  return (
    <BrowserRouter>
      <Admin
        authProvider={authProvider}
        loginPage={LoginPage}
        dataProvider={dataProvider}
        title="SAAS series listing"
      >
        <Resource
          name="last_week"
          list={ProductList}
          show={ProductShow}
          options={{ label: 'Last week' }}
        />
        <Resource
          name="last_month"
          list={ProductList}
          show={ProductShow}
          options={{ label: 'Last month' }}
        />
        <Resource name="series" list={ProductList} show={ProductShow} />
        <Resource name="movies" list={ProductList} show={ProductShow} />
        <Resource
          name="variety"
          list={ProductList}
          show={ProductShow}
          options={{ label: 'Variety' }}
        />
        <Resource
          name="anime"
          list={ProductList}
          show={ProductShow}
          options={{ label: 'Anime' }}
        />
      </Admin>
    </BrowserRouter>
  );
}

export default App;
