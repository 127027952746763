import React from 'react';
import { Login } from 'react-admin';
import LoginButton from './LoginButton';

const LoginPage = () => {
  return (
    <Login>
      <LoginButton />
    </Login>
  );
};

export default LoginPage;
