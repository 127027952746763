import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const apiUrl = 'https://listing-api.saas.tozorlabs.com/api';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  // const token = localStorage.getItem('token');
  // options.headers.set('Authorization', `Bearer ${token}`);
  options.credentials = 'include';
  return fetchUtils.fetchJson(url, options);
};

// const apiClient = (uri: string, options: any = {}) => {
//   return httpClient(`${apiUrl}${uri}`, options);
// };

const baseDataProvider = simpleRestProvider(apiUrl, httpClient);

export const dataProvider = {
  ...baseDataProvider,
};
