import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  List,
  SelectInput,
  TextField,
  UrlField,
  useResourceContext,
} from 'react-admin';

import { EpisodeCounter } from './EpisodeCounter';
import { UploadedMoment } from './UploadedMoment';

type SelectItemType = {
  id: string;
  name: string;
};

export const originCountriesList: SelectItemType[] = [
  { id: '', name: 'Unknown' },
  { id: 'CN', name: 'China' },
  { id: 'HK', name: 'Hong Kong' },
  { id: 'JP', name: 'Japan' },
  { id: 'KR', name: 'South Korea' },
  { id: 'MY', name: 'Malaysia' },
  { id: 'SG', name: 'Singapore' },
  { id: 'TH', name: 'Thailand' },
  { id: 'TW', name: 'Taiwan' },
];

const SeriesFilter = (props: any) => (
  <Filter {...props}>
    {/* <TextInput label='Search for title' source='title' alwaysOn /> */}
    <SelectInput
      source="country"
      choices={originCountriesList}
      label="Filter by country"
      optionText="name"
      optionValue="name"
    />
    <BooleanInput
      source="is_completed"
      label="Completed?"
      defaultValue={true}
    />
  </Filter>
);

export const ProductList = (props: any) => {
  const resource: string = useResourceContext();
  let sorting = { field: 'full_title', order: 'ASC' };
  let needType = false;
  if (resource === 'last_week' || resource === 'last_month') {
    sorting = { field: 'last_file_timestamp', order: 'DESC' };
    needType = true;
  }
  return (
    <List
      {...props}
      // actions={null}
      exporter={false}
      bulkActionButtons={null}
      perPage={25}
      filters={<SeriesFilter />}
      sort={sorting}
    >
      <Datagrid rowClick="show" isRowSelectable={() => false}>
        <TextField source="full_title" label="Title" sortByOrder="ASC" />
        {needType && <TextField source="type" />}
        <TextField source="country" />
        <DateField source="release_date" label="Aired" sortByOrder="DESC" />
        <TextField source="native_title" label="Native title" />
        <BooleanField source="is_completed" label="Completed" />
        <EpisodeCounter
          source="episode_number"
          label="Episodes"
          sortable={false}
        />
        <UrlField
          source="mdl_url"
          label="MDL"
          target="_blank"
          sortable={false}
        />
        <UploadedMoment
          source="last_file_timestamp"
          label="Uploaded"
          sortByOrder="DESC"
        />
      </Datagrid>
    </List>
  );
};
