import React from 'react';
import moment from 'moment';
import { useRecordContext } from 'react-admin';

export const UploadedMoment = (props: any) => {
  const record = useRecordContext(props);
  if (record.last_file_timestamp == null) {
    return <span></span>;
  } else {
    const timestamp: number = record.last_file_timestamp;
    const lastChanged = new Date(timestamp * 1000);
    const delta = moment(lastChanged).fromNow();
    return <span>{delta}</span>;
  }
};
